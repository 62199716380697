
var swiper = new Swiper(".inclusiva-slider__tipografia", {
    spaceBetween: 60,
    speed: 800,
    autoplay: {
        delay: 5000,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    a11y: {
        containerMessage: 'Slider sobre tipografía',
        containerRoleDescriptionMessage: 'Diapositivas',
        firstSlideMessage: 'Esta es la primera diapositiva',
        itemRoleDescriptionMessage: 'Slide',
        lastSlideMessage: 'Esta es la última dispositiva',
        nextSlideMessage: 'Siguiente dispositiva',
        prevSlideMessage: 'Diapositiva anterior'

    },
});